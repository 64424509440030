<template>
  <div class="misc-wrapper">
    <b-link class="brand-logo">
      <b-img
        src="@/assets/images/logo/edubotlong.png"
        alt="logo"
        height="50rem"
        width="185rem"
        class="mt-1"
      />
    </b-link>

    <div class="misc-inner p-2 p-sm-3">
      <div class="w-100 text-center">
        <h2 class="mb-1">
          Sem licenças suficientes! 📄
        </h2>
        <p class="mb-2">
          Entre em contato com seu gestor ou com o EduBot!
        </p>
        <b-button
          variant="primary"
          class="mb-1 btn-sm-block"
          @click="goToLogin"
        >
          Login!
        </b-button>
        <div>
          <b-img
            fluid
            class="mt-3"
            :src="imgUrl"
            alt="Not authorized page"
            width="300px"
          />
        </div>
        
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import { BLink, BImg, BButton } from 'bootstrap-vue'
import store from '@/store/index'
import axios from "@axios";
import { initialAbility } from '@/libs/acl/config'
// import { getHomeRouteForLoggedInUser } from '@/auth/utils'

export default {
  components: {
    BLink, BImg, BButton,
  },
  data() {
    return {
      downImg: require('@/assets/images/pages/not_license.svg'),
    }
  },
  computed: {
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.downImg = require('@/assets/images/pages/not_license.svg')
        return this.downImg
      }
      return this.downImg
    },
  },
  methods: {
    // loginRoute() {
    //   const user = JSON.parse(localStorage.getItem('userData'))
    //   return getHomeRouteForLoggedInUser(user ? user.role : null)
    // },
    goToLogin() {
      axios.post("logout", {}).then(() => {
          localStorage.removeItem('userData')
          localStorage.removeItem(["accessToken"]);
          localStorage.removeItem(["expiresIn"]);
          localStorage.clear();

          // Reset ability
          this.$ability.update(initialAbility)

          this.$router.push({ name: 'login' })
      })
    },
    loginRoute() {
      return getHomeRouteForLoggedInUser()
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-misc.scss';
</style>
